





























































































import { Component, Mixins } from 'vue-property-decorator';
import { Profile, ListingStatus, WebsiteLevel, MapSettings } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';
import { ListingFilter, ListingOrderBy, TestimonialFilter, TestimonialOrderBy } from 'client-website-ts-library/filters';
import { AsyncView, RouteMeta } from 'client-website-ts-library/plugins';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import { ContextItemType } from 'client-website-ts-library/types/Context';

import Listings from '@/components/Listings.vue';
import TestimonialList from '@/components/Testimonials/TestimonialList.vue';
import Loader from '@/components/UI/Loader.vue';

import { AnalyticsEventObject } from 'client-website-ts-library/services/Analytics';
import Form from '@/components/Forms/Form.vue';

const Map = () => import('client-website-ts-library/components/Map/Map.vue');

@Component({
  components: {
    Listings,
    TestimonialList,
    Loader,
    Form,
    Map,
  },
})
export default class AgentProfile extends Mixins(AsyncView) {
private profile: Profile | null = null;

private listingFilter: ListingFilter | null = null;

private currentListingFilter: ListingFilter | null = null;

private soldListingFilter: ListingFilter | null = null;

private testimonialFilter: TestimonialFilter | null = null;

private hasSoldListings = true;

private hasCurrentListings = true;

private hasTestimonials = true;

private mapSettings: MapSettings | null = null;

private formData: FormConstructorData | null = null;

resolveId() {
  const { id } = this.$route.params;

  if (id.length === 36) {
    // If the ID is 36 long we'll treat it as a guid
    this.updateAgent(id);
  } else {
    API.Slugs.Resolve(id).then((resolved) => {
      if (resolved.Type === 'Profile') {
        this.updateAgent(resolved.Id);
      }
    });
  }
}

stripHtml(html: string): string {
  const el = document.createElement('div');

  el.innerHTML = html;

  return el.innerText;
}

head(): RouteMeta | null {
  if (this.profile === null) return null;

  let about = this.stripHtml(this.profile.AboutAgent);

  if (about.length > 156) {
    about = `${about.substring(0, 156)}...`;
  }

  return {
    title: `${this.profile.FullName} - ${this.profile.Position}`,
    description: about,
    image: this.profile.Photo ? this.profile.Photo.Preview.Url : undefined,
  };
}

updateAgent(id: string) {
  API.Profiles.Get(id, true).then((profile) => {
    this.profile = profile;

    this.$emit('updateView');

    this.currentListingFilter = new ListingFilter({
      SearchLevel: WebsiteLevel.Profile,
      SearchGuid: this.profile!.Id,
      Statuses: [
        ListingStatus.Current,
        ListingStatus.UnderContract,
      ],
      OrderByStatements: [
        ListingOrderBy.CreateDateDesc,
      ],
      PageSize: 6,
    });

    this.formData = new FormConstructorData([
      {
        Type: ContextItemType.EnquiryTargetProfile,
        Id: this.profile!.Id,
      },
    ]);

    this.soldListingFilter = new ListingFilter({
      SearchLevel: WebsiteLevel.Profile,
      SearchGuid: this.profile!.Id,
      Statuses: [
        ListingStatus.Sold,
        ListingStatus.Leased,
      ],
      OrderByStatements: [
        ListingOrderBy.LastUpdatedDateDesc,
      ],
      PageSize: 6,
    });

    this.testimonialFilter = new TestimonialFilter({
      SearchLevel: WebsiteLevel.Profile,
      SearchGuid: this.profile!.Id,
      OrderByStatements: [
        TestimonialOrderBy.CreateDateDesc,
      ],
    });

    this.mapSettings = new MapSettings({
      Zoom: 20,
      Interactive: false,
      Padding: 80,
      Markers: [
        {
          Title: `${this.profile.Office.FranchiseName} ${this.profile.Office.OfficeName}`,
          Address: `${this.profile.Office.AddressLine1} ${this.profile.Office?.AddressLine2}`,
          Coordinate: this.profile.Office.Coordinate,
        },
      ],
    });
  });
}

mounted() {
  this.resolveId();
}

getAnalyticsData(): AnalyticsEventObject | null {
  if (this.profile === null) return null;

  return AnalyticsEventObject.FromProfile(this.profile);
}
}
